import debounce from 'es6-promise-debounce';

import ACTIONS from '../../actions';
import Api from '../../api';

import { DATE, FULLTIME } from '../../../constants/time';
import { RATETYPE } from '../../constants/rateType';
import {
  TYPERATRATES,
  ACCOUNTVALUE,
  FIELDS_PLAN_FEE_SETTINGS,
} from '../../constants/account';
import { CURRENCY_VALUES } from '../../constants/hotel';

import { formatDate } from '../../utils/formatDate';
import debounceXhr from '../../utils/debounce';
import { getMappedAccountSettings, setMappedAccountSettings } from '../../utils/account';

let store = null;
const DEBOUNCE_TIME = 400;

const autocompleteHotelDebounce = debounce(Api.Trip.autocompleteHotel, DEBOUNCE_TIME);

class AccountService {
  constructor() {
    store = this.getStore('Account');

    this.autocompleteCompaniesGroup = debounceXhr(Api.Account.accountAutocomplete, DEBOUNCE_TIME);
  }

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);

  loadAccount = (accountId) => Api.Account.getAccount(accountId)
    .then((res) => store.dispatch({
      type: ACTIONS.ACCOUNT.LOADACCOUNT,
      account: res,
    }));

  setAirlineAggregation = (value) => store.dispatch({
    type: ACTIONS.ACCOUNT.SET_AIRLINE_AGGREGATION,
    payload: value,
  });

  loadAccountData = (accountId) => Promise.allSettled([
    Api.Account.getAccount(accountId),
    Api.Account.getAggregation(accountId),
    Api.Account.getAggregationAirline(accountId),
    Api.Account.getSpecialBill(accountId),
    Api.Account.getInfoAboutCompaniesGroup(accountId),
    Api.Account.getSmartAgent(accountId),
    Api.Account.getSmartAgentAuto(accountId),
    Api.Account.getSmartwayKZ(accountId),
  ]).then((data) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.LOADACCOUNT,
      account: {
        ...data[0].value,
        companiesGroup: data[4].value || null,
      },
      specialBillData: data[3].value || null,
    });

    this.setAirlineAggregation(data[2].value.toLowerCase());
    this.updateStoreAggregation(data[1].value || 0);
    this.updateSmartAgent(data[5].value);
    this.updateSmartAgentAuto(data[6].value);
    this.updateSmartwayKZ(data[7].value);
  });

  loadAccountForEstablishedLimits = (accountId) => Api.Account.getAccount(accountId)
    .then((res) => store.dispatch({
      type: ACTIONS.ACCOUNT.LOAD_ACCOUNT_FOR_ESTABLISHED_LIMITS,
      account: res,
    }));

  updateSmartAgent = (res) => store.dispatch({
    type: ACTIONS.ACCOUNT.UPDATE_SMART_AGENT,
    payload: res,
  });

  updateSmartwayKZ = (res) => {
    if (res) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.UPDATE_SMARTWAY_KZ,
        payload: res,
      });
    }
  };

  updateSmartAgentAuto = (res) => {
    if (res) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.UPDATE_SMART_AGENT_AUTO,
        payload: res,
      });
    }
  };

  getSmartAgent = async (accountId) => {
    try {
      const res = await Api.Account.getSmartAgent(accountId);

      this.updateSmartAgent(res);
    } catch (e) {
      this.updateSmartAgent(false);
    }
  };

  getSmartAgentAuto = async (accountId) => {
    try {
      const res = await Api.Account.getSmartAgentAuto(accountId);

      this.updateSmartAgentAuto(res);
    } catch (e) {
      this.updateSmartAgentAuto(false);
    }
  };

  getSmartwayKZ = async (accountId) => {
    try {
      const res = await Api.Account.getSmartwayKZ(accountId);

      this.updateSmartwayKZ(res);
    } catch (e) {
      this.updateSmartwayKZ(null);
    }
  };

  getCompaniesInAccount = (accountId) => Api.Account.getCompaniesInAccount(accountId)
    .then((res) => store.dispatch({
      type: ACTIONS.ACCOUNT.COMPANIES_IN_ACCOUNT,
      subAccounts: res,
    }));

  updateLoadingAccountSettings = (value) => store.dispatch({
    type: ACTIONS.ACCOUNT.UPDATE_LOADING_ACCOUNT_SETTINGS,
    loading: value,
  });

  updateLoadingLimits = (value) => store.dispatch({
    type: ACTIONS.ACCOUNT.LOADING_LIMITS,
    loading: value,
  });

  loadAccountSettings = async (accountId) => {
    try {
      const res = await Api.Account.getAccountSettings(accountId);
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_ACCOUNT_SETTINGS,
        accountSettings: getMappedAccountSettings(res),
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_ACCOUNT_SETTINGS,
        accountSettings: null,
      });
    }
  };

  updateAccount = (newAccount) => {
    const {
      PlanFeeSettings, AccountType, DisableAccountInvoice,
    } = newAccount;

    const planFeeSettings = PlanFeeSettings.Rate === 0 ? null : PlanFeeSettings;
    const disableAccountInvoice = DisableAccountInvoice === ACCOUNTVALUE.OFF;
    const preparedNewAccount = {
      ...newAccount,
      PlanFeeSettings: planFeeSettings,
      AccountType,
      DisableAccountInvoice: disableAccountInvoice,
    };

    return Api.Account.updateAccount(preparedNewAccount).then(() => store.dispatch({
      type: ACTIONS.ACCOUNT.LOADACCOUNT,
      account: preparedNewAccount,
    }));
  };

  loadSpecialBill = (accountId) => Api.Account.getSpecialBill(accountId)
    .then((specialBillData) => {
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_SPECIAL_BILL,
        specialBillData,
      });
    })
    .catch(() => {
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_SPECIAL_BILL,
        specialBillData: null,
      });

      return new Promise((resolve, reject) => {
        reject();
      });
    });

  loadAccountSupports = () => Api.Account.getAccountSupportsList();

  accountAutocomplete = (query) => {
    try {
      if (this.xhrAutocompleteCompaniesGroup) this.xhrAutocompleteCompaniesGroup.abort();

      this.xhrAutocompleteCompaniesGroup = this.autocompleteCompaniesGroup({ Query: query.trim() });

      return this.xhrAutocompleteCompaniesGroup;
    } catch (e) {
      return null;
    }
  };

  addAccountToCompaniesGroup = async (accountId, mainAccountId, Accounts) => {
    try {
      const res = await Api.Account.addAccountToCompaniesGroup(accountId, mainAccountId);
      if (res === null) {
        store.dispatch({
          type: ACTIONS.ACCOUNT.LOAD_COMPANIES_GROUP,
          Accounts,
        });
      }

      return res;
    } catch (e) {
      return e;
    }
  };

  removeAccountFromCompaniesGroup = async (accountId, Accounts) => {
    try {
      const res = await Api.Account.deleteAccountFromCompaniesGroup(accountId);
      if (res === null) {
        store.dispatch({
          type: ACTIONS.ACCOUNT.LOAD_COMPANIES_GROUP,
          Accounts,
        });
      }

      return res;
    } catch (e) {
      return e;
    }
  };

  getInfoAboutCompaniesGroup = async (accountId) => {
    try {
      const res = await Api.Account.getInfoAboutCompaniesGroup(accountId);

      return res;
    } catch (e) {
      return e;
    }
  };

  updateSpecialBill = (specialBillData) => Api.Account.updateSpecialBill(specialBillData)
    .then(() => {
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_SPECIAL_BILL,
        specialBillData,
      });
    })
    .catch(() => new Promise((resolve, reject) => {
      reject();
    }));

  deleteSpecialBill = (accountId) => Api.Account.deleteSpecialBill(accountId)
    .then(() => {
      store.dispatch({
        type: ACTIONS.ACCOUNT.LOAD_SPECIAL_BILL,
        specialBillData: null,
      });
    })
    .catch(() => new Promise((resolve, reject) => {
      reject();
    }));

  loadCompanyFunds = (companyId) => Api.Company.getFunds(companyId).then((res) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.LOADCOMPANYFUNDS,
      funds: res,
    });
  });

  updateOnlyAccount = (accountId) => Api.Account.getAccount(accountId).then((res) => store.dispatch({
    type: ACTIONS.ACCOUNT.LOADACCOUNT,
    account: res,
  }));

  addPlanFeeSettingEntity = () => store.dispatch({ type: ACTIONS.ACCOUNT.ADD_PLAN_FEE_SETTING_ENTITY });

  showPlanFeeSettingsDialog = () => store.dispatch({ type: ACTIONS.ACCOUNT.SHOW_PLAN_FEE_SETTING_DIALOG });

  showAccountSettingsDialog = () => store.dispatch({ type: ACTIONS.ACCOUNT.SHOW_ACCOUNT_SETTINGS_DIALOG });

  showEstablishedLimitsDialog = () => store.dispatch({ type: ACTIONS.ACCOUNT.SHOW_ESTABLISHED_LIMITS_DIALOG });

  closePlanFeeSettingsDialog = () => {
    this.showPlanFeeSettingsDialog();

    store.dispatch({ type: ACTIONS.ACCOUNT.CLOSE_PLAN_FEE_SETTINGS });
  };

  closeAccountSettingsDialog = () => {
    this.showAccountSettingsDialog();

    store.dispatch({ type: ACTIONS.ACCOUNT.CLOSE_ACCOUNT_SETTINGS });
  };

  removePlanFeeSettings = (index) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.REMOVE_PLAN_FEE_SETTINGS,
      index,
    });
  };

  updateFieldPlanFeeData = (planFeeSettings) => store.dispatch({
    type: ACTIONS.ACCOUNT.UPDATE_PLAN_FEE_SETTINGS,
    planFeeSettings,
  });

  changePlanFeeSettings = (value, field, index) => {
    const { planFeeSettings, account: { Companies } } = store.getState();

    let name = planFeeSettings[index].company;

    if (field === FIELDS_PLAN_FEE_SETTINGS.COMPANY_ID && value !== 0) {
      const findName = Companies.find(({ CompanyId }) => CompanyId === value);
      name = findName.ShortCompanyName || findName.CompanyName;
    }
    const newPlanFeeItem = {
      ...planFeeSettings[index],
      [field]: value,
      company: name,
    };

    const newPlanFeeSettings = [
      ...planFeeSettings.slice(0, index),
      newPlanFeeItem,
      ...planFeeSettings.slice(index + 1),
    ];

    this.updateFieldPlanFeeData(newPlanFeeSettings);
  };

  updateAccountSettings = (accountSettings) => (
    Api.Account.updateAccountSettings(setMappedAccountSettings(accountSettings))
      .then(debounceXhr(() => this.loadAccountSettings(accountSettings.accountId), DEBOUNCE_TIME))
  );

  updatePlanFeeSettings = (accountId, planFeeSettings) => {
    const filterPlanFee = planFeeSettings
      .filter(({ currentRate, companyId }) => currentRate.type !== TYPERATRATES.DEFAULT && companyId !== 0);

    const data = filterPlanFee.length
      ? filterPlanFee.map(({
        companyId, currentRate, regularityPay, updatedRate, dateUpdatedPlanfee,
      }) => ({
        AccountId: accountId,
        CompanyId: companyId,
        Rate: currentRate.value,
        Type: regularityPay,
        updatedRate: dateUpdatedPlanfee ? parseInt(updatedRate, 10) : null,
        dateUpdatedPlanfee: dateUpdatedPlanfee ? formatDate(dateUpdatedPlanfee, FULLTIME) : null,
      }))
      : [];

    if (!data.length) {
      return Api.Account.deletePlanFeeSettings(accountId).then(this.updateOnlyAccount(accountId));
    }

    return Api.Account.updatePlanFeeSettings(data).then(this.updateOnlyAccount(accountId));
  };

  loadAccountPassengerScheme = async (accountId) => {
    try {
      const res = await Api.Account.getAccountSettings(accountId);
      const { settings1C: { bookByPassengerScheme } } = res;

      return bookByPassengerScheme;
    } catch (e) {
      return null;
    }
  };

  loadAggregation = (value) => store.dispatch({
    type: ACTIONS.ACCOUNT.LOAD_AGGREGATION,
    payload: value,
  });

  updateStoreAggregation = (value) => store.dispatch({
    type: ACTIONS.ACCOUNT.UPDATE_AGGREGATION,
    payload: value,
  });

  updateAggregation = (aggregationId) => {
    this.loadAggregation(true);

    const { account: { Id } } = this.get();

    if (!aggregationId) {
      return Api.Account.deleteAggregation(Id, aggregationId)
        .then(() => this.updateStoreAggregation(0))
        .finally(() => this.loadAggregation(false));
    }

    return Api.Account.updateAggregation(Id, aggregationId)
      .then((res) => this.updateStoreAggregation(res))
      .finally(() => this.loadAggregation(false));
  };

  getRates = (rateType) => {
    switch (rateType) {
      case RATETYPE.HOTEL: {
        return Api.Account.getHotelRates(this.get().account.Id);
      }
      case RATETYPE.AIRLINE: {
        return Api.Account.getAirlineRates();
      }
      case RATETYPE.TRAIN: {
        return Api.Account.getTrainRates();
      }
      case RATETYPE.BUS: {
        return Api.Account.getBusRates();
      }
    }

    return Promise.reject();
  };

  updateRates = (rateType, value) => {
    let promise = null;
    switch (rateType) {
      case RATETYPE.HOTEL: {
        promise = Api.Account.updateHotelRate(this.get().account.Id, value);
        break;
      }
      case RATETYPE.AIRLINE: {
        promise = Api.Account.updateAirlineRate(this.get().account.Id, value);
        break;
      }
      case RATETYPE.TRAIN: {
        promise = Api.Account.updateTrainRate(this.get().account.Id, value);
        break;
      }
      case RATETYPE.BUS: {
        promise = Api.Account.updateBusRate(value);
        break;
      }
    }

    promise.then(() => store.dispatch({
      type: ACTIONS.ACCOUNT.UPDATERATES,
      rateType,
      value,
    }));

    return promise;
  };

  addCompany = (company) => {
    const contract = company.Contract.Number
      ? {
        ...company.Contract,
        StartDate: formatDate(company.Contract.StartDate, DATE),
      }
      : null;
    const preparedCompany = {
      ...company,
      Contract: contract,
    };

    return Api.Account.addCompany(this.get().account.Id, preparedCompany)
      .then((res) => store.dispatch({
        type: ACTIONS.ACCOUNT.UPDATECOMPANIES,
        company: res,
      }));
  };

  downloadSalesReport = (accountId, startDate, endDate, type) => {
    const formatStartDate = formatDate(startDate, DATE);
    const formatEndDate = formatDate(endDate, DATE);

    return Api.Account.downloadSalesReport(accountId, formatStartDate, formatEndDate, type);
  };

  getAmoId = async (accountId) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.GET_AMO_ID,
      amoId: 0,
      loadAmoId: true,
    });
    try {
      const res = await Api.Account.getAmoId(accountId);
      store.dispatch({
        type: ACTIONS.ACCOUNT.GET_AMO_ID,
        amoId: res,
        loadAmoId: false,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.GET_AMO_ID,
        amoId: 0,
        loadAmoId: false,
      });
    }
  };

  changeAccountDisabledLoading = () => store.dispatch({ type: ACTIONS.ACCOUNT.ACCOUNT_DISABLED });

  accountDisabled = async (accountId) => {
    this.changeAccountDisabledLoading();
    await Promise.all([
      Api.Account.accountDisabled(accountId),
      this.loadAccount(accountId),
    ]).catch(() => new Promise((resolve, reject) => {
      this.changeAccountDisabledLoading();
      reject();
    }));

    this.changeAccountDisabledLoading();

    return Promise.resolve();
  };

  getExtendedInfo = (accountId) => Api.Account.getExtendedInfo(accountId);

  updateExtendedInfo = (accountId, info) => Api.Account.updateExtendedInfo(accountId, info);

  loadSettingAggregation = async (accountId) => {
    try {
      const [hotelAggregation, listHideHotelTypes] = await Promise.all([
        Api.Account.getSettingAggregation(accountId),
        Api.Account.getTypes(),
      ]);
      const { Hide } = hotelAggregation;

      store.dispatch({
        type: ACTIONS.ACCOUNT.UPDATE_HOTEL_AGGREGATION,
        payload: {
          hotelAggregation: {
            AccountId: hotelAggregation.AccountId,
            Hotels: Hide.Hotels || [],
            HotelTypes: Hide.HotelTypes || [],
            ExceptHotels: Hide.ExceptHotels || [],
          },
          listHideHotelTypes,
        },
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.UPDATE_HOTEL_AGGREGATION,
        payload: {
          hotelAggregation: {
            AccountId: null,
            Hotels: [],
            HotelTypes: [],
            ExceptHotels: [],
          },
          listHideHotelTypes: [],
        },
      });
    }
  };

  updateSettingAggregation = (value) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.UPDATE_HIDE_TYPES_HOTEL_AGGREGATION,
      payload: value,
    });
  };

  addHotels = (field, value) => {
    const { hotelAggregation } = store.getState();

    const isInArray = hotelAggregation[field].some((item) => item.Id === value.Id);

    if (!isInArray) {
      store.dispatch({
        type: ACTIONS.ACCOUNT.ADD_HOTELS,
        payload: { field, value },
      });
    }
  };

  deleteHotels = (field, id) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.DELETE_HOTELS,
      payload: { field, id },
    });
  };

  updateHotelAggregation = async () => {
    const {
      hotelAggregation: {
        AccountId,
        Hotels,
        HotelTypes,
        ExceptHotels,
      },
    } = store.getState();

    store.dispatch({
      type: ACTIONS.ACCOUNT.UPDATE_LOADING_HOTEL_AGGREGATION,
      payload: true,
    });

    const data = {
      AccountId,
      Hide: {
        HotelsId: Hotels.map(({ Id }) => Id),
        HotelTypes,
        ExceptHotelsId: ExceptHotels.map(({ Id }) => Id),
      },
    };

    await Api.Account.updateSettingAggregation(data);

    store.dispatch({
      type: ACTIONS.ACCOUNT.UPDATE_LOADING_HOTEL_AGGREGATION,
      payload: false,
    });
  };

  autocompleteHotel = (value, ids) => (value ? autocompleteHotelDebounce({ query: value, aId: ids }) : null);

  updateCurrencyInfo = (field, value) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.CHANGE_CURRENCY_INFO,
      payload: { field, value },
    });
  };

  getExchangeCurrency = async (name = CURRENCY_VALUES.USD) => {
    const res = await Api.Trip.getExchangeRates(name);

    store.dispatch({
      type: ACTIONS.ACCOUNT.CHANGE_EXCHANGE_CURRENCY,
      payload: res[0],
    });
  };

  getPricesBookingMarkup = async (data) => {
    try {
      const res = await Api.Trip.getPricesBookingMarkup(data);

      store.dispatch({
        type: ACTIONS.ACCOUNT.CHANGE_PRICES_CURRENCY,
        payload: res,
      });
      store.dispatch({
        type: ACTIONS.ACCOUNT.CHANGE_EXCHANGE_CURRENCY,
        payload: res.Currency,
      });
    } catch (err) {
      return null;
    }
  };

  addPenaltyCurrency = () => {
    store.dispatch({ type: ACTIONS.ACCOUNT.ADD_PENALTIES_CURRENCY });
  };

  upadatePenaltiesCurrency = (field, value, idx) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.UPDATE_PENALTIES_CURRENCY,
      payload: {
        field, value, idx,
      },
    });
  };

  removePenaltyCurrency = (idx) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.REMOVE_PENALTY_CURRENCY,
      payload: { idx },
    });
  };

  resetFormCurrency = () => {
    store.dispatch({ type: ACTIONS.ACCOUNT.RESET_FORM_CURRENCY });
  };

  changeLeadType = (value) => {
    store.dispatch({
      type: ACTIONS.ACCOUNT.CHANGE_LEAD_TYPE,
      payload: value,
    });
  };

  getLeadType = async (accountId) => {
    try {
      const { LeadType } = await Api.Account.getLeadType(accountId);

      if (LeadType) {
        this.changeLeadType(LeadType);
      }
    } catch (error) {
      this.changeLeadType('');
    }
  };

  saveLeadType = async (accountId, value) => {
    const leadInfo = {
      AccountId: accountId,
      LeadType: value,
    };

    await Api.Account.saveLeadType(leadInfo);
  };

  deleteLeadType = async (accountId) => {
    await Api.Account.deleteLeadType(accountId);
  };
}

export default AccountService;
